<template lang="pug">
.layout--main(
  class="navbar-floating"
)
  div(
    :class="[contentAreaClass, {'show-overlay': bodyOverlay}]"
  )
    div.content-wrapper#navbar-map
      the-navbar(
        :navbarColor='navbarColor'
        :class="[{'text-white': isNavbarDark && !isThemeDark}, {'text-base': !isNavbarDark && isThemeDark}]"
      )
  div.entro
    img.img-fixed(
      :src='require(`@/assets/images/logo_letras.png`)'
    )
  router-view
</template>
<script>
import TheNavbar from "../components/TheNavbarMap.vue"
import themeConfig from "@/../themeConfig.js"
export default {
  components: {
    TheNavbar
  },
  data () {
    return {
      navbarColor: themeConfig.navbarColor || "#fff",
      isNavbarDark: false,
    }
  },
  computed: {
    isAppPage() {
      if (this.$route.path.includes("/apps/")) return true
      else return false
    },
    isThemeDark() {
      return this.$store.state.theme == "dark"
    },
    sidebarWidth() {
      return this.$store.state.sidebarWidth
    },
    bodyOverlay() {
      return this.$store.state.bodyOverlay
    },
    contentAreaClass() {
      if (this.sidebarWidth == "default") return "content-area-default"
      else if (this.sidebarWidth == "reduced") return "content-area-reduced"
      else if (this.sidebarWidth) return "content-area-full"
    },
    navbarClasses() {
      return {
        "navbar-hidden": this.navbarType == "hidden",
        "navbar-sticky": this.navbarType == "sticky",
        "navbar-static": this.navbarType == "static",
        "navbar-floating": this.navbarType == "floating"
      }
    },
    footerClasses() {
      return {
        "footer-hidden": this.footerType == "hidden",
        "footer-sticky": this.footerType == "sticky",
        "footer-static": this.footerType == "static"
      }
    }
  }
}
</script>

<style>
  body {
    padding: 0;
    margin: 0;
  }
  html, body, .map-response, .dashboard-response, #app {
      height: 100%;
      width: 100%;
  }
  .vs-navbar {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  #navbar-map .vx-navbar-wrapper {
    background: none !important;
  }
  .img-fixed {
    left: 82px;
    bottom: 10px;
    height: 40px;
    position: fixed;
    z-index: 9999;
  }
</style>
<style scoped>
  #content-area {
    height: auto;
  }
  #content-area .content-wrapper {
    min-height: auto;
  }
</style>